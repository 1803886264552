import React, { useState , useEffect, createContext, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";


const API_PATH = 'https://api.trainer-app.com/'
const APIContext = createContext({
    getWorkouts: () => {},
    userMetadata: {},
})

export const useApi = () => useContext(APIContext)

export const APIContextProvider = ({children}) => {
    const { getAccessTokenSilently, getAccessTokenWithPopup, user, isAuthenticated, isLoading } = useAuth0();
    const [accessToken, setAccessToken] = useState(null);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        const getUserMetadata = async () => {
            setLoading(true);
        
            try {
                const at = await getAccessTokenSilently({
                    audience: `https://auth0-jwt-authorizer`,
                    redirect_uri: `${window.location.origin}/callback`
                });

                setAccessToken(at);
          
            } catch (e) {
                if(e.message === 'Consent required'){
                    getAccessTokenWithPopup();
                }
                console.log(e.message);
            }
            setLoading(false);
        };
        if(isAuthenticated){
            getUserMetadata();
        }
    }, [getAccessTokenSilently, isAuthenticated]);
    if(loading || isLoading){
        return <div></div>
    }
    return (
        <APIContext.Provider value={{ 
            accessToken,
            getWorkouts: (startDate, endDate) => fetchApi(accessToken, `workouts/?userId=${user.sub}&startDate=${startDate}&endDate=${endDate}`),
            saveWorkoutDetails: (workoutId, body) => fetchApi(accessToken, `workouts/?workoutId=${workoutId}`,{ method: 'PATCH', body: JSON.stringify(body) }),
            getCurrentUser: () => fetchApi(accessToken, `users/?userId=${user.sub}`),
            getUser: (userId) => fetchApi(accessToken, `users/?userId=${userId}`),
            getAllUsers: () => fetchApi(accessToken, `users/`),
            addUser: (body) => fetchApi(accessToken, `users/`, { method: 'POST', body: JSON.stringify(body) }),
            updateUser: (body) => fetchApi(accessToken, `users/?userId=${user.sub}`, { method: 'PATCH', body: JSON.stringify(body) }),
            addWorkoutForUser: (userId, body) => fetchApi(accessToken,`workouts/?userId=${userId}`, { method: 'POST', body: JSON.stringify(body) }),
            getWorkoutsForUser: (userId, startDate, endDate) => fetchApi(accessToken, `workouts/?userId=${userId}&startDate=${startDate}&endDate=${endDate}`),
            createSubscription: (stripeCustomerId) => fetchApi(accessToken, 'payments/', { method: 'PATCH', body: JSON.stringify({ stripeCustomerId, id: user.sub })}),
            getSubscription: (stripeCustomerId) => fetchApi(accessToken, `subscriptions/?stripeCustomerId=${stripeCustomerId}`),
            cancelSubscription: (subscriptionId) => fetchApi(accessToken, 'subscriptions/', {method: 'DELETE', body: JSON.stringify({ subscriptionId })} ),
            uncancelSubscription: (subscriptionId) => fetchApi(accessToken, 'subscriptions/', {method: 'PATCH', body: JSON.stringify({ subscriptionId })} ),
            createCustomer: (body) => fetchApi(accessToken, 'payments/', { method: 'POST', body: JSON.stringify(body)}),
            getSetupIntent: () => fetchApi(accessToken, 'payments/'),
            getPaymentMethod: (stripeCustomerId) => fetchApi(accessToken, `payment-methods/?stripeCustomerId=${stripeCustomerId}`),
            submitClientIntake: (body) => fetchApi(accessToken, 'client-intakes/', { method: 'POST', body: JSON.stringify(body)}),
            getAllClientIntakes: () => fetchApi(accessToken, 'client-intakes/'),
            addBatchWorkouts: (body) => fetchApi(accessToken, 'batch-workouts/', { method: 'POST', body: JSON.stringify(body)}),
            getSignedUrl: () => fetchApi(accessToken, 'videos/'),
            getExerciseHistory: (exerciseName, userId=user.sub) => fetchApi(accessToken, `history/?userId=${userId}&exerciseName=${exerciseName}`)
        }}>
            {children}
        </APIContext.Provider>
    )
}

const fetchApi = (token, path = "", opts = {}) => { 
    return fetch(`${API_PATH}${path}`, {
        credentials: 'same-origin',
        headers: {
            'authorization': `Bearer ${token}`
        },
        ...opts
    }).then(r => r.json()).catch(e => console.error(e));
};