import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from "@auth0/auth0-react";
import AdapterDateFns from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider, createTheme } from  '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import reportWebVitals from './reportWebVitals';
import { APIContextProvider } from './utils/api';
import { UserInfoContextProvider } from './utils/user';
import Loading from './components/Layout/Loading';

const WebApp = React.lazy(() => import('./App'));
const MobileApp = React.lazy(() => import('./MobileApp'));

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff0000'
    }
  },
  typography: {
    fontFamily: [
      'Roboto Mono',
      'monospace',
    ].join(','),
  }
});
console.log('app type', process.env.REACT_APP_MOBILE_APP)
ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <Auth0Provider
      domain="trainer-app.us.auth0.com"
      clientId="qciL4xOnd4OZnALOjh0AIGFo3UgY4CW3"
      redirectUri={`${window.location.origin}/callback`}
      audience="https://auth0-jwt-authorizer"
      scope="read:current_user update:current_user_metadata read:roles"
      useRefreshTokens
      cacheLocation="localstorage"
  >
    <APIContextProvider>
      <UserInfoContextProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <React.Suspense fallback={<Loading style={{ m: 4 }} />}>
              {process.env.REACT_APP_TYPE === 'mobile' ? (
                <MobileApp />
              ) : (
                <WebApp />
              )}
            </React.Suspense>
            
          </ThemeProvider>
        </LocalizationProvider>
      </UserInfoContextProvider>
    </APIContextProvider>
  </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
